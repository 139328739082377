type Task<T> = () => Promise<T>;
type TaskRequest<T> = {
  task: Task<T>;
  resolve: (value: T) => void;
  reject: (reason: any) => void;
};

export default class Queue<T> {
  private tasks = [] as TaskRequest<T>[];
  call(task: Task<T>) {
    return new Promise<T>((resolve, reject) => {
      const taskRequest = {
        task,
        resolve,
        reject
      };
      this.tasks.push(taskRequest);
      if (this.tasks.length === 1) {
        this.runTask(taskRequest);
      }
    });
  }
  private runTask(taskRequest: TaskRequest<T>) {
    const promise = taskRequest.task();
    promise
      .then(taskRequest.resolve)
      .catch(taskRequest.reject)
      .finally(() => {
        this.tasks.splice(
          this.tasks.indexOf(taskRequest),
          1
        );
        if (this.tasks.length) {
          this.runTask(this.tasks[0]);
        }
      });
  }
};
