import { useState, useCallback } from 'react';
import { adminToken } from '../../lib/api';
import { useAbortSignal } from '../../lib/hooks';
import {
  useNotify
} from 'react-admin';

const usePurchase = () => {
  const [ type, setType ] = useState('');
  const [ token, setToken ] = useState('');
  const [ submitting, setSubmitting ] = useState(false);
  const signal = useAbortSignal();
  const notify = useNotify();

  const submit = useCallback(async () => {
    try {
      setSubmitting(true);
      const result = await adminToken(
        {
          type,
          token
        },
        signal
      );
      if (result.message) {
        notify(result.message);
      }
    }
    catch (err) {
      if (/abort/gi.test(`${err.message}`)) {
        return;
      }
      console.log(err);
    }
    finally{
      setSubmitting(false);
    }
  }, [
    type,
    token,
    notify,
    signal
  ]);
  
  return {
    type,
    setType,
    token,
    setToken,
    submit,
    submitting
  };
};

export {
  usePurchase
};