import React from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box
} from '@material-ui/core';
import {
  useTranslate
} from 'react-admin';
import { usePurchase } from './hooks';
import SubmitIcon from '@material-ui/icons/Done';

const Purchase: React.FC = () => {
  const translate = useTranslate();

  const {
    type,
    setType,
    token,
    setToken,
    submit,
    submitting
  } = usePurchase();

  return (
    <Paper>
      <Box p={1}>
        <Box m={4}>
          <Typography variant={'h6'}>
            {translate('pages.users.components.purchase.title')}
          </Typography>
        </Box>
        <Box m={4}>
          <TextField
            id={'type'}
            required
            fullWidth
            label={translate('pages.users.components.purchase.type')}
            variant={'filled'}
            value={type}
            onChange={e => {
              setType(e.target.value)
            }}
          />
        </Box>
        <Box m={4}>
          <TextField
            id={'token'}
            required
            fullWidth
            label={translate('pages.users.components.purchase.token')}
            variant={'filled'}
            value={token}
            onChange={e => {
              setToken(e.target.value)
            }}
          />
        </Box>
        <Box m={4}>
          <Button
            variant='contained'
            color='primary'
            size='medium'
            startIcon={<SubmitIcon/>}
            onClick={submit}
            disabled={!token || !type || submitting}
          >
            {translate('pages.users.components.purchase.submit')}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default Purchase;