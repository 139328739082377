import React from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Box
} from '@material-ui/core';
import {
  useTranslate
} from 'react-admin';
import { useMail } from './hooks';
import SubmitIcon from '@material-ui/icons/Email';

interface MailProps {
  id: number;
}

const Mail: React.FC<MailProps> = (props) => {
  const translate = useTranslate();

  const {
    content,
    setContent,
    test,
    setTest,
    submit,
    submitting
  } = useMail(props.id);

  return (
    <Paper>
      <Box p={1}>
        <Box m={4}>
          <Typography variant={'h6'}>
            {translate('pages.companies.components.mail.title')}
          </Typography>
        </Box>
        <Box m={4}>
          <TextField
            id={'content'}
            required
            fullWidth
            multiline
            rows={6}
            label={translate('pages.companies.components.mail.content')}
            variant={'filled'}
            value={content}
            onChange={e => {
              setContent(e.target.value)
            }}
          />
        </Box>
        <Box m={4}>
          <TextField
            id={'test'}
            fullWidth
            label={translate('pages.companies.components.mail.test')}
            variant={'filled'}
            value={test}
            onChange={e => {
              setTest(e.target.value)
            }}
          />
        </Box>
        <Box m={4}>
          <Button
            variant='contained'
            color='primary'
            size='medium'
            startIcon={<SubmitIcon/>}
            onClick={submit}
            disabled={!content || submitting}
          >
            {translate('pages.companies.components.mail.submit')}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default Mail;