import { useState, useCallback } from 'react';
import { adminMail } from '../../lib/api';
import { useAbortSignal } from '../../lib/hooks';
import {
  useNotify,
  useTranslate
} from 'react-admin';

const useMail = (id: number) => {
  const [ content, setContent ] = useState('');
  const [ test, setTest ] = useState('');
  const [ submitting, setSubmitting ] = useState(false);
  const signal = useAbortSignal();
  const translate = useTranslate();
  const notify = useNotify();

  const submit = useCallback(async () => {
    try {
      setSubmitting(true);
      const result = await adminMail(
        {
          id,
          content,
          test: test || undefined
        },
        signal
      );
      if (result.message) {
        notify(result.message);
      } else {
        notify(translate('pages.companies.components.mail.success'))
      }
    }
    catch (err) {
      if (/abort/gi.test(`${err.message}`)) {
        return;
      }
      console.log(err);
    }
    finally{
      setSubmitting(false);
    }
  }, [
    id,
    content,
    test,
    notify,
    translate,
    signal
  ]);
  
  return {
    content,
    setContent,
    test,
    setTest,
    submit,
    submitting
  };
};

export {
  useMail
};