import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { FunctionalityType } from '.';
import {
  Functionality
} from '../../lib/api';
import {
  useInput,
  useTranslate
} from 'react-admin';
import { useFormState } from 'react-final-form';

interface FunctionalityRow {
  name: string;
  title: string;
  description: string;
  expiresAt: Date | null;
  devicesLimit: string;
  usersLimit: string;
};

const useFunctionality = (source: string, type: FunctionalityType) => {
  const [
    functionalities,
    setFunctionalities
  ] = useState<Functionality[]>([]);
  const [
    functionalityRows,
    setFunctionalityRows
  ] = useState<FunctionalityRow[]>([]);
  const translate = useTranslate();

  const {
    input: {
      onChange: onSubmit
    },
    meta: {
      initial
    }
  } = useInput({source});

  

  const run = useCallback(() => {
    if (!initial) {
      return;
    }
    const {
      load: {
        getFunctionalityListUser,
        getFunctionalityListCompany
      }
    } = initial;
    const functionalities = (type === FunctionalityType.User) ?
      getFunctionalityListUser as Functionality[] :
      getFunctionalityListCompany as Functionality[];
    setFunctionalities(functionalities);
  }, [
    type,
    initial
  ]);

  useEffect(() => {
    run()
  }, [
    run
  ]);

  useEffect(() => {
    const functionalityRows = functionalities
      .map(functionality => {
        let expiresAt = null as Date | null;
        if (functionality.expiresAt) {
          const [day, month, year] = functionality.expiresAt
            .split('.');
          expiresAt = new Date(+year, +month - 1, +day);
        }
        return {
          name: functionality.name,
          title: translate(
            `components.functionality.functionalities.${
              functionality.name
            }.title`
          ),
          description: translate(
            `components.functionality.functionalities.${
              functionality.name
            }.description`
          ),
          expiresAt,
          devicesLimit: `${functionality.devicesLimit}`,
          usersLimit: `${functionality.usersLimit}`,
        } as FunctionalityRow;
      });
    setFunctionalityRows(functionalityRows);
  }, [
    functionalities,
    translate
  ]);

  const onChange = useCallback((changedRow: FunctionalityRow) => {
    const rows = functionalityRows.slice();
    const index = rows.findIndex(
      (row) => row.name === changedRow.name
    );
    if (index !== -1) {
      rows.splice(index, 1, changedRow);
      setFunctionalityRows(rows);
    }
  }, [
    functionalityRows
  ]);

  const { id } = useFormState().values;

  const submit = useMemo(() => {
    const initialRows = functionalities
      .map(functionality => {
        let expiresAt = null as Date | null;
        if (functionality.expiresAt) {
          const [day, month, year] = functionality.expiresAt
            .split('.');
          expiresAt = new Date(+year, +month - 1, +day);
        }
        return {
          name: functionality.name,
          expiresAt,
          devicesLimit: `${functionality.devicesLimit}`,
          usersLimit: `${functionality.usersLimit}`
        } as FunctionalityRow;
      });
    const changed = [] as FunctionalityRow[];
    for (let row of functionalityRows) {
      const initialRow = initialRows.find(
        initialRow => initialRow.name === row.name
      );
      if (!initialRow) continue;
      if (
        `${row.expiresAt}` !== `${initialRow.expiresAt}` ||
        row.usersLimit !== initialRow.usersLimit ||
        row.devicesLimit !== initialRow.devicesLimit
      ) {
        changed.push(row);
      }
    }

    if (!changed.length) {
      return {};
    }
    const newRows = changed
      .filter(row => row.expiresAt);
    const deletedRows = changed
      .filter(row => !row.expiresAt);
    
    if (newRows.length) {
      const list = newRows.map(
        row => ({
          name: row.name,
          expiresAt: row.expiresAt ? ''.concat(
            `${row.expiresAt.getFullYear()}-`,
            `${row.expiresAt.getMonth() + 1}`.padStart(2, '0'),
            '-',
            `${row.expiresAt.getDate()}`.padStart(2, '0')
          ) : '',
          devicesLimit: row.devicesLimit ?
            +row.devicesLimit : 0,
          usersLimit: row.usersLimit ?
            +row.usersLimit : 0
        })
      );
      if (type === FunctionalityType.User) {
        return {
          createFunctionalityUser: {
            id,
            list
          }
        };
      } else {
        return {
          createFunctionalityCompany: {
            id,
            list
          }
        };
      }
    }
    if (deletedRows.length) {
      const functionalityList = deletedRows.map(
        row => row.name
      );
      if (type === FunctionalityType.User) {
        return {
          removeFunctionalityUser: {
            id,
            functionalityList
          }
        };
      } else {
        return {
          removeFunctionalityCompany: {
            id,
            functionalityList
          }
        };
      }
    }
  }, [
    id,
    functionalities,
    functionalityRows,
    type
  ]);

  const submitRef = useRef(submit);

  useEffect(() => {
    if (
      JSON.stringify(submitRef.current) !==
      JSON.stringify(submit)
    ) {
      onSubmit({
        ...initial,
        submit
      })
      submitRef.current = submit;
    }
  }, [
    initial,
    onSubmit,
    submit
  ]);

  return {
    functionalityRows,
    onChange,
    submit
  };
};

export {
  useFunctionality
};
