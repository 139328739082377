import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  Filter,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import { ApiResource } from '../../lib/api';

const MapanyServersFilter: React.FC = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source='id'/>
      <TextInput source='name'/>
      <ReferenceInput
        source='companyId'
        reference={ApiResource.Companies}
        filterToQuery={(searchText: string) => ({
          name: [searchText]
        })}
      >
        <AutocompleteInput optionText='name'/>
      </ReferenceInput>
      <TextInput source='host'/>
      <TextInput source='key'/>
      <BooleanInput source='isEnabled'/>
      <TextInput source='hostName'/>
    </Filter>
  );
};

const MapanyServersList: React.FC = (props: any) => {
  return (
    <List
      filters={<MapanyServersFilter/>}
      {...props}
    >
      <Datagrid
        rowClick='edit'
      >
        <TextField source='id'/>
        <TextField source='name'/>
        <ReferenceField
          source='companyId'
          reference={ApiResource.Companies}>
          <TextField source='name'/>
        </ReferenceField>
        <TextField source='host'/>
        <TextField source='key'/>
        <BooleanField source='isEnabled'/>
        <TextField source='hostName'/>
      </Datagrid>
    </List>
  );
};

export default MapanyServersList;