import React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  useTranslate
} from 'react-admin';
import { ApiResource } from '../../lib/api';
import CustomFormTab from '../../components/CustomFormTab';
import AccessesInput, {
  AccessesType
} from '../../components/accesses-input';
import FunctionalityInput, {
  FunctionalityType
} from '../../components/functionality-input';
import Purchase from './Purchase';
import RestoreToolbar from './RestoreToolbar';

const UsersTitle = ({ record }: any) => {
  const translate = useTranslate();
  const title = ''.concat(
    translate('resources.users.name', 1),
    ' ',
    record ? 
      record.username :
    ''
  );
  return <span>{title}</span>;
};

const UsersEdit: React.FC = (props: any) => {
  const translate = useTranslate();

  return (
    <Edit
      title={<UsersTitle/>}
      undoable={false}
      {...props}
    >
      <TabbedForm
        toolbar={<RestoreToolbar/>}
        redirect={false}
      >
        <FormTab label={translate('pages.users.tabs.summary')}>
          <TextInput source='id' disabled/>
          <TextInput
            source='username'
            validate={[
              required()
            ]}
          />
          <TextInput source='password'/>
          <TextInput source='email'/>
          <BooleanInput source='tester'/>
          <BooleanInput source='companyAdmin'/>
          <BooleanInput source='superAdmin'/>
          <TextInput
            source='position'
            fullWidth
            multiline
          />
          <ReferenceInput
            source='companyId'
            reference={ApiResource.Companies}
            filterToQuery={(searchText: string) => ({
              name: [searchText]
            })}
            allowEmpty
            perPage={150}
            sort={{
              field: 'name',
              order: 'ASC'
            }}
          >
            <AutocompleteInput
              optionText='name'
              allowEmpty
              emptyValue={0}
            />
          </ReferenceInput>
          <BooleanInput source='dsp'/>
          <BooleanInput source='multi'/>
          <TextInput source='firstname'/>
          <TextInput source='middlename'/>
          <TextInput source='lastname'/>
          <TextInput source='phone'/>
          <TextInput
            source='description'
            fullWidth
            multiline
          />
        </FormTab>
        <FormTab
          label={translate('pages.users.tabs.accesses')}
        >
          <AccessesInput
            source={'accesses'}
            type={AccessesType.User}
          />
        </FormTab>
        <FormTab
          label={translate('pages.users.tabs.functionality')}
        >
          <FunctionalityInput
            source={'functionality'}
            type={FunctionalityType.User}
          />
        </FormTab>
        <CustomFormTab
          label={translate('pages.users.tabs.purchase')}
        >
          <Purchase/>
        </CustomFormTab>
      </TabbedForm>
    </Edit>
  );
};

export default UsersEdit;