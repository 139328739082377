import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  required
} from 'react-admin';
import { ApiResource } from '../../lib/api';

const MapanyServersEdit: React.FC = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source='id' disabled/>
        <TextInput
          source='name'
          validate={[
            required()
          ]}
        />
        <ReferenceInput
          source='companyId'
          reference={ApiResource.Companies}
          filterToQuery={(searchText: string) => ({
            name: [searchText]
          })}
          allowEmpty
          perPage={150}
          sort={{
            field: 'name',
            order: 'ASC'
          }}
        >
          <AutocompleteInput
            optionText='name'
            allowEmpty
            emptyValue={0}
          />
        </ReferenceInput>
        <TextInput
          source='host'
          validate={[
            required()
          ]}
        />
        <TextInput
          source='key'
          fullWidth
          validate={[
            required()
          ]}
        />
        <BooleanInput source='isEnabled'/>
        <TextInput source='hostName'/>
      </SimpleForm>
    </Edit>
  );
};

export default MapanyServersEdit;