import React, { useCallback, useState } from 'react';
import {
  Toolbar,
  useTranslate,
  useNotify,
  useRefresh
} from 'react-admin';
import {
  Button,
  CircularProgress
} from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/RestoreFromTrash';
import { useAbortSignal } from '../../lib/hooks';
import { restoreUser } from '../../lib/api';

const RestoreToolbar: React.FC<any> = (props) => {
  const { record } = props;
  const isDeleted = (
    !!record &&
    record.deleted &&
    !record.enabled
  );
  const id = record && +record.id;
  const translate = useTranslate();
  const signal = useAbortSignal();
  const notify = useNotify();
  const refresh = useRefresh();
  const [ submitting, setSubmitting ] = useState(false);

  const onRestore = useCallback(async () => {
    if (!id) {
      return;
    }
    try {
      setSubmitting(true);
      const success = await restoreUser(id, signal);

      if (success) {
        notify(translate('pages.users.components.restoretoolbar.success'));
        refresh();
      }
    }
    catch (err) {
      if (/abort/gi.test(`${err.message}`)) {
        return;
      }
      notify(err.message);
    }
    finally{
      if (!signal.aborted) {
        setSubmitting(false);
      }
    }
  }, [
    id,
    notify,
    refresh,
    translate,
    signal
  ]);

  return (
    <Toolbar
      {...props}
    >
      {isDeleted ?
        <Button
          variant='contained'
          color='primary'
          size='medium'
          startIcon={<RestoreIcon/>}
          onClick={onRestore}
          disabled={submitting}
        >
          {submitting ?
            <CircularProgress
              size={18}
              thickness={2}
            /> :
            null
          }
          {translate('pages.users.components.restoretoolbar.restore')}
        </Button> :
        null
      }
    </Toolbar>
  );
};

export default RestoreToolbar;