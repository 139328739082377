import React from 'react';
import {
  Toolbar,
  SaveButton
} from 'react-admin';

const OnlySaveToolbar: React.FC<any> = (props) => {
  return (
    <Toolbar
      {...props}
    >
      <SaveButton disabled={props.pristine}/>
    </Toolbar>
  );
};

export default OnlySaveToolbar;