import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  useTranslate
} from 'react-admin';
import { ApiResource } from '../../lib/api';

const AccessTimesFilter: React.FC = (props: any) => {
  const translate = useTranslate();
  const userLabel = translate(
    'resources.users.fields.username'
  );

  return (
    <Filter {...props}>
      <TextInput source='id'/>
      <ReferenceInput
        source='userId'
        label={userLabel}
        reference={ApiResource.Users}
        filterToQuery={(searchText: string) => ({
          username: [searchText]
        })}
      >
        <AutocompleteInput optionText='username'/>
      </ReferenceInput>
      <ReferenceInput
        source='companyId'
        reference={ApiResource.Companies}
        filterToQuery={(searchText: string) => ({
          name: [searchText]
        })}
      >
        <AutocompleteInput optionText='name'/>
      </ReferenceInput>
      <DateTimeInput source='time'/>
    </Filter>
  );
};

const AccessTimesList: React.FC = (props: any) => {
  return (
    <List
      filters={<AccessTimesFilter/>}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid>
        <TextField source='id'/>
        <ReferenceField
          source='userId'
          reference={ApiResource.Users}>
          <TextField source='username'/>
        </ReferenceField>
        <ReferenceField
          source='companyId'
          reference={ApiResource.Companies}>
          <TextField source='name'/>
        </ReferenceField>
        <DateField
          source='time'
          locales='ru-RU'
          showTime
        />
      </Datagrid>
    </List>
  );
};

export default AccessTimesList;