import * as React from 'react';
import { FC, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MuiTab from '@material-ui/core/Tab';
import classnames from 'classnames';
import { useTranslate, Record } from 'react-admin';

const hiddenStyle = { display: 'none' };

export interface FormTabProps {
  basePath?: string;
  className?: string;
  contentClassName?: string;
  hidden?: boolean;
  icon?: ReactElement;
  intent?: 'header' | 'content';
  label: string;
  margin?: 'none' | 'normal' | 'dense';
  record?: Record;
  resource?: string;
  value?: string;
  variant?: 'standard' | 'outlined' | 'filled';
}

const CustomFormTab: FC<FormTabProps> = (props) => {
  const {
    basePath,
    className,
    contentClassName,
    children,
    hidden,
    icon,
    intent,
    label,
    margin,
    record,
    resource,
    variant,
    value,
    ...rest
  } = props;
  const translate = useTranslate();
  const location = useLocation();

  const renderHeader = () => (
    <MuiTab
      key={label}
      label={translate(label, { _: label })}
      value={value}
      icon={icon}
      className={classnames('form-tab', className)}
      component={Link}
      to={{ ...location, pathname: value }}
      {...rest}
    />
  );

  const renderContent = () => (
    <span
      style={hidden ? hiddenStyle : undefined}
      className={contentClassName}
    >
      {children}
    </span>
  );

  return intent === 'header' ? renderHeader() : renderContent();
};

export default CustomFormTab;