import React from 'react';
import clsx from 'clsx';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import {
  TableCell,
  Checkbox
} from '@material-ui/core';
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps
} from 'react-virtualized';

declare module '@material-ui/core/styles/withStyles' {
  // Augment the BaseCSSProperties so that we can control jss-rtl
  interface BaseCSSProperties {
    /*
     * Used to control if the rule-set should be affected by rtl transformation
     */
    flip?: boolean;
  }
}

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      '& .ReactVirtualized__Table__headerRow': {
        flip: false,
        paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
      },
    },
    tableRow: {
      cursor: 'pointer',
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
    },
    headCell: {
      fontWeight: 'bold',
    },
    separatorRow: {
      textTransform: 'uppercase',
      backgroundColor: theme.palette.grey[200],
    },
    noClick: {
      cursor: 'initial',
    },
  });

export interface ColumnData {
  dataKey: string;
  label: string;
  type: 'string' | 'number' | 'checkbox';
  width: number;
}

interface Row {
  index: number;
}

interface MuiVirtualizedTableProps extends WithStyles<typeof styles> {
  columns: ColumnData[];
  headerHeight?: number;
  onRowClick?: (row: Row) => void;
  allSelected?: boolean;
  onSelectAllClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => any;
  rowHeight?: number;
  separatorRowIndices?: number[];
}

class MuiVirtualizedTable extends React.PureComponent<MuiVirtualizedTableProps> {
  static defaultProps = {
    headerHeight: 52,
    rowHeight: 52,
  };

  getRowClassName = ({ index }: Row) => {
    const {
      classes,
      onRowClick,
      separatorRowIndices = []
    } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
      [classes.separatorRow]: separatorRowIndices.includes(index)
    });
  };

  cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;

    const className = clsx(classes.tableCell, classes.flexContainer, {
      [classes.noClick]: onRowClick == null,
    });

    const align = (
      columns[columnIndex].type === 'number'
    ) ? 'right' :
      columns[columnIndex].type === 'checkbox' ?
        'center' :
        'left';

    const padding = (
      columns[columnIndex].type === 'checkbox'
    ) ? '0 0 0 4px' : 'default';

    return (
      <TableCell
        component='div'
        className={className}
        variant='body'
        style={{
          height: rowHeight,
          padding
        }}
        align={align}
      >
        {(columns[columnIndex].type === 'checkbox') ?
          <Checkbox
            checked={cellData}
          /> :
          cellData
        }
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }: TableHeaderProps & { columnIndex: number }) => {
    const {
      headerHeight,
      columns,
      classes,
      allSelected,
      onSelectAllClick
    } = this.props;
    const align = (
      columns[columnIndex].type === 'number'
    ) ? 'right' :
      columns[columnIndex].type === 'checkbox' ?
        'center' :
        'left';
    const padding = (
      columns[columnIndex].type === 'checkbox'
    ) ? '0 0 0 4px' : 'default';

    return (
      <TableCell
        component='div'
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick,
          classes.headCell
        )}
        variant='head'
        style={{
          height: headerHeight,
          padding
        }}
        align={align}
      >
        {columns[columnIndex].type === 'checkbox' ?
          <Checkbox
            checked={allSelected}
            onClick={() => {
              if (onSelectAllClick) {
                onSelectAllClick();
              }
            }}
          /> :
          <span>{label}</span>
        }
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight!}
            gridStyle={{
              direction: 'inherit',
            }}
            headerHeight={headerHeight!}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);
export default VirtualizedTable;