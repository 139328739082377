import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { AccessesType } from '.';
import {
  useInput,
  useTranslate
} from 'react-admin';
import { AccessList } from '../../lib/api';
import { useFormState } from 'react-final-form';

export enum AccessType {
  AhSchema = 'ahSchema',
  CartographyMap = 'cartographyMap',
  DistrictSchema = 'districtSchema',
  MapBox = 'mapBox',
  NavData = 'navData',
  Procedures = 'procedures'
};

export enum AhSchemaType {
  Ad = 'AD',
  Ls = 'LS'
};

export enum MapBoxType {
  Ifr = 'ifr',
  Vfr = 'vfr'
}

interface Access {
  uuid: string;
  type: AccessType;
  name: string;
  printLimit?: number;
  usersLimit?: number;
  expiresAt: string;
  isAd?: boolean;
  isLs?: boolean;
  isIfr?: boolean;
  isVfr?: boolean;
};

interface AccessRow {
  uuid?: string;
  type: AccessType;
  typeName: string;
  name?: string;
  printLimit?: number;
  usersLimit?: number;
  expiresAt?: string;
  selected?: boolean;
  isSeparator?: boolean;
};

const useAccesses = (source: string, type: AccessesType) => {
  const [
    accesses,
    setAccesses
  ] = useState<Access[]>([]);

  const {
    meta: {
      initial
    }
  } = useInput({source});

  const run = useCallback(() => {
    if (!initial) {
      return;
    }
    const {
      load: {
        getAccessListUser,
        getAccessListCompany
      }
    } = initial;
    const accessList = (type === AccessesType.User) ?
      getAccessListUser as AccessList :
      getAccessListCompany as AccessList;

    const accesses = [] as Access[];

    if (accessList.navData) {
      for (let access of accessList.navData) {
        accesses.push({
          uuid: access.uuid,
          type: AccessType.NavData,
          name: access.name,
          expiresAt: access.expiresAt,
          usersLimit: access.usersLimit,
          isIfr: !!access.name.match(/ifr/i),
          isVfr: !!access.name.match(/vfr/i)
        });
      }
    }
    if (accessList.procedures) {
      for (let access of accessList.procedures) {
        accesses.push({
          uuid: access.uuid,
          type: AccessType.Procedures,
          name: access.name,
          expiresAt: access.expiresAt,
          usersLimit: access.usersLimit,
          isIfr: true
        });
      }
    }
    if (accessList.cartographyMap) {
      for (let access of accessList.cartographyMap) {
        accesses.push({
          uuid: access.uuid,
          type: AccessType.CartographyMap,
          name: access.name,
          expiresAt: access.expiresAt,
          usersLimit: access.usersLimit,
          isIfr: !!access.name.match(/ifr/i),
          isVfr: !!access.name.match(/vfr/i)
        });
      }
    }
    if (accessList.mapBox) {
      for (let access of accessList.mapBox) {
        accesses.push({
          uuid: access.data.uuid,
          type: AccessType.MapBox,
          name: access.data.name,
          expiresAt: access.data.expiresAt,
          usersLimit: access.data.usersLimit,
          isIfr: access.type === MapBoxType.Ifr,
          isVfr: access.type === MapBoxType.Vfr
        });
      }
    }
    if (accessList.districtSchema) {
      for (let access of accessList.districtSchema) {
        accesses.push({
          uuid: access.data.uuid,
          type: AccessType.DistrictSchema,
          name: access.data.name,
          expiresAt: access.data.expiresAt,
          usersLimit: access.data.usersLimit,
          printLimit: access.printLimit,
          isIfr: true,
          isVfr: true
        });
      }
    }
    if (accessList.ahSchema) {
      for (let access of accessList.ahSchema) {
        accesses.push({
          uuid: access.data.data.uuid,
          type: AccessType.AhSchema,
          name: access.data.data.name,
          expiresAt: access.data.data.expiresAt,
          usersLimit: access.data.data.usersLimit,
          printLimit: access.data.printLimit,
          isAd: access.type === AhSchemaType.Ad,
          isLs: access.type === AhSchemaType.Ls,
          isIfr:
            access.flightRules === '010' ||
            access.flightRules === '011' ||
            access.flightRules === '111',
          isVfr:
            access.flightRules === '100' ||
            access.flightRules === '101' ||
            access.flightRules === '111'
        });
      }
    }
    setAccesses(accesses);
  }, [
    type,
    initial
  ]);

  useEffect(() => {
    run();
  }, [
    run
  ]);

  return accesses;
};

const useAccessRows = (source: string, type: AccessesType) => {
  const [
    accessRows,
    setAccessRows
  ] = useState<AccessRow[]>([]);

  const [
    selectedUuids,
    setSelectedUuids
  ] = useState<string[]>([]);

  const [
    selectedGroups,
    setSelectedGroups
  ] = useState<AccessType[]>([]);

  const accesses = useAccesses(source, type);

  const {
    filtered,
    isAd,
    switchAd,
    isLs,
    switchLs,
    isIfr,
    switchIfr,
    isVfr,
    switchVfr,
    isOpen,
    switchOpen
  } = useAccessFilter(accesses);
  const translate = useTranslate();

  const allSelected = useMemo(() => {
    return filtered.length > 0 && filtered
      .map(access => access.uuid)
      .every(uuid => selectedUuids.includes(uuid))
  }, [
    selectedUuids,
    filtered
  ]);

  const separatorRowIndices = useMemo(() => {
    return accessRows.reduce(
      (separatorRowIndices, current, i) => 
        current.isSeparator ?
          separatorRowIndices.concat([i]) :
          separatorRowIndices,
      [] as number[]
    );
  }, [
    accessRows
  ]);

  const select = useCallback((rowIndex: number) => {
    const row = accessRows[rowIndex];
    const group = row.type;
    const groupUuids = filtered
      .filter(access => access.type === group)
      .map(access => access.uuid);

    if (!row.isSeparator) {
      const access = row as Access;
      const uuid = access.uuid;
      const index = selectedUuids.indexOf(uuid);
  
      if (index !== -1) {
        setSelectedUuids(
          selectedUuids.filter((_, i) => i !== index)
        );
      } else {
        const newSelectedUuids = selectedUuids.concat(
          [uuid]
        );
        setSelectedUuids(newSelectedUuids);
      }
    } else {
      if (selectedGroups.includes(group)) {
        setSelectedUuids(
          selectedUuids.filter(
            uuid => !groupUuids.includes(uuid)
          )
        );
      } else {
        setSelectedUuids(
          selectedUuids.concat(
            groupUuids.filter(
              uuid => !selectedUuids.includes(uuid)
            )
          )
        );
      }
    }
  }, [
    accessRows,
    filtered,
    selectedGroups,
    selectedUuids
  ]);

  const selectAll = useCallback(() => {
    const filteredUuids = filtered
      .map(access => access.uuid);

    if (!allSelected) {
      setSelectedUuids(
        selectedUuids.concat(
          filteredUuids
            .filter(uuid => !selectedUuids.includes(uuid))
        )
      );
    } else {
      setSelectedUuids(
        selectedUuids
          .filter(uuid => !filteredUuids.includes(uuid))
      );
    }
  }, [
    filtered,
    allSelected,
    selectedUuids
  ]);

  useEffect(() => {
    const allGroups = [] as AccessType[];
    const selectedGroups = [] as AccessType[];

    for (let access of filtered) {
      if (!allGroups.includes(access.type)) {
        allGroups.push(access.type);
      }
    }

    for (let group of allGroups) {
      const groupUuids = filtered
        .filter(access => access.type === group)
        .map(access => access.uuid);
      if (groupUuids.every(
        groupUuid => selectedUuids.includes(groupUuid)
      )) {
        selectedGroups.push(group);
      }
    }

    setSelectedGroups(selectedGroups);
  }, [
    filtered,
    selectedUuids
  ]);

  useEffect(() => {
    const accessRows = [] as AccessRow[];
    const addedGroups = [] as AccessType[];

    for (let access of filtered) {
      const type = access.type;
      if (!addedGroups.includes(type)) {
        accessRows.push({
          type,
          typeName: translate(`components.accesses.types.${type}`),
          isSeparator: true,
          selected: selectedGroups.includes(type)
        });
        addedGroups.push(type);
      }

      accessRows.push({
        ...access,
        typeName: translate(`components.accesses.types.${type}`),
        selected: selectedUuids.includes(access.uuid)
      });
    }
    setAccessRows(accessRows);
  }, [
    translate,
    filtered,
    selectedUuids,
    selectedGroups
  ]);

  return {
    accessRows,
    select,
    allSelected,
    selectAll,
    separatorRowIndices,
    isAd,
    switchAd,
    isLs,
    switchLs,
    isIfr,
    switchIfr,
    isVfr,
    switchVfr,
    isOpen,
    switchOpen
  };
};

const useAccessFilter = (accesses: Access[]) => {
  const [ filtered, setFiltered ] = useState<Access[]>([]);

  const [ isAd, setAd ] = useState(false);
  const switchAd = useCallback(() => {
    setAd(ad => !ad);
  }, []);
  
  const [ isLs, setLs ] = useState(false);
  const switchLs = useCallback(() => {
    setLs(ls => !ls);
  }, []);

  const [ isIfr, setIfr ] = useState(false);
  const switchIfr = useCallback(() => {
    setIfr(ifr => !ifr);
  }, []);

  const [ isVfr, setVfr ] = useState(false);
  const switchVfr = useCallback(() => {
    setVfr(vfr => !vfr);
  }, []);

  const [ isOpen, setOpen ] = useState(false);
  const switchOpen = useCallback(() => {
    setOpen(open => !open);
  }, []);

  useEffect(() => {
    setFiltered(accesses
      .filter(access => isAd ? access.isAd : true)
      .filter(access => isLs ? access.isLs : true)
      .filter(access => isIfr ? access.isIfr : true)
      .filter(access => isVfr ? access.isVfr : true)
      .filter(access => isOpen ? access.expiresAt : true)
    );
  }, [
    accesses,
    isAd,
    isLs,
    isIfr,
    isVfr,
    isOpen
  ]);

  return {
    filtered,
    isAd,
    switchAd,
    isLs,
    switchLs,
    isIfr,
    switchIfr,
    isVfr,
    switchVfr,
    isOpen,
    switchOpen
  };
};

const useAccessForm = (
  source: string,
  type: AccessesType,
  accessRows: AccessRow[]
) => {
  const {
    input: {
      onChange
    },
    meta: {
      initial
    },
  } = useInput({source});

  const { id } = useFormState().values;

  const [
    printLimit,
    setPrintLimit
  ] = useState<number | null>(null);

  const [
    userLimit,
    setUserLimit
  ] = useState<number | null>(null);

  const [
    expiresAt,
    setExpiresAt
  ] = useState<Date | null>(null);

  const onChangePrintLimit = useCallback(
    (value?: number | string) => {
      const printLimit = value !== undefined ?
        +value : null;
      setPrintLimit(printLimit);
    }, []
  );

  const onChangeUserLimit = useCallback(
    (value?: number | string) => {
      const userLimit = value !== undefined ?
        +value : null;
      setUserLimit(userLimit);
    }, []
  );

  const onChangeExpiresAt = useCallback(
    (date: Date | null, value?: string | null) => {
      setExpiresAt(date);
    }, []
  );

  const submit = useMemo(() => {
    const selected = accessRows
      .filter(accessRow => accessRow.selected);
    if (!selected.length) {
      return {};
    }
    const navData = selected
      .filter(
        accessRow => accessRow.type === AccessType.NavData
      )
      .filter(accessRow => accessRow.uuid)
      .map(accessRow => accessRow.uuid as string);
    const procedures = selected
      .filter(
        accessRow => accessRow.type === AccessType.Procedures
      )
      .filter(accessRow => accessRow.uuid)
      .map(accessRow => accessRow.uuid as string);
    const cartographyMap = selected
      .filter(
        accessRow => accessRow.type === AccessType.CartographyMap
      )
      .filter(accessRow => accessRow.uuid)
      .map(accessRow => accessRow.uuid as string);
    const mapBox = selected
      .filter(
        accessRow => accessRow.type === AccessType.MapBox
      )
      .filter(accessRow => accessRow.uuid)
      .map(accessRow => accessRow.uuid as string);
    const districtSchema = selected
      .filter(
        accessRow => accessRow.type === AccessType.DistrictSchema
      )
      .filter(accessRow => accessRow.uuid)
      .map(accessRow => accessRow.uuid as string);
    const ahSchema = selected
      .filter(
        accessRow => accessRow.type === AccessType.AhSchema
      )
      .filter(accessRow => accessRow.uuid)
      .map(accessRow => accessRow.uuid as string);
    
    
    if (expiresAt) {
      const date = ''.concat(
        `${expiresAt.getFullYear()}-`,
        `${expiresAt.getMonth() + 1}`.padStart(2, '0'),
        '-',
        `${expiresAt.getDate()}`.padStart(2, '0')
      );

      if (type === AccessesType.User) {
        return {
          createAccessUser: {
            accessList: {
              id,
              navData,
              procedures,
              cartographyMap,
              mapBox,
              districtSchema,
              ahSchema
            },
            date,
            printLimits: printLimit || 0
          }
        };
      } else {
        return {
          createAccessCompany: {
            accessList: {
              id,
              navData,
              procedures,
              cartographyMap,
              mapBox,
              districtSchema,
              ahSchema
            },
            date,
            printLimits: printLimit || 0,
            userLimits: userLimit || 0
          }
        };
      }
    } else {
      if (type === AccessesType.User) {
        return {
          removeAccessUser: {
            id,
            navData,
            procedures,
            cartographyMap,
            mapBox,
            districtSchema,
            ahSchema
          }
        };
      } else {
        return {
          removeAccessCompany: {
            id,
            navData,
            procedures,
            cartographyMap,
            mapBox,
            districtSchema,
            ahSchema
          }
        };
      }
    }
  }, [
    id,
    type,
    accessRows,
    expiresAt,
    printLimit,
    userLimit,
  ]);

  const submitRef = useRef(submit);

  useEffect(() => {
    if (
      JSON.stringify(submitRef.current) !==
      JSON.stringify(submit)
    ) {
      onChange({
        ...initial,
        submit
      })
      submitRef.current = submit;
    }
  }, [
    initial,
    onChange,
    submit
  ]);

  return {
    printLimit,
    onChangePrintLimit,
    userLimit,
    onChangeUserLimit,
    expiresAt,
    onChangeExpiresAt
  };
};

export {
  useAccessRows,
  useAccessForm
};