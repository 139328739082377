import {
  DataProvider,
  GetListParams,
  CreateParams,
  DeleteParams,
  DeleteManyParams,
  GetOneParams,
  GetManyParams,
  GetManyReferenceParams,
  Record,
  UpdateParams,
  UpdateManyParams
} from 'react-admin';

import {
  ApiResource,
  usersGetList,
  companiesGetList,
  companiesGetMany,
  accessTimesGetList,
  usersGetMany,
  usersGetOne,
  usersUpdate,
  usersCreate,
  usersDelete,
  companiesGetOne,
  companiesUpdate,
  companiesCreate,
  companiesDelete,
  mapanyServersGetList,
  mapanyServersGetOne,
  mapanyServersUpdate,
  mapanyServersCreate,
  mapanyServersDelete,
  getAccessListUser,
  getFunctionalityListUser,
  getAccessListCompany,
  getFunctionalityListCompany,
  createAccessUser,
  removeAccessUser,
  createAccessCompany,
  removeAccessCompany,
  createFunctionalityUser,
  removeFunctionalityUser,
  createFunctionalityCompany,
  removeFunctionalityCompany,
  isCompanyAdmin,
  setCompanyAdmin
} from './api';



export default class ApiProvider implements DataProvider {
  async getList<RecordType extends Record>(
    resource: string,
    params: GetListParams
  ) {
    // console.log('getList', resource, params);
    let data = [] as Record[];
    let total = 0;

    const request = {
      sortName: params.sort.field,
      sortAsc: params.sort.order === 'ASC',
      offset: (params.pagination.page -1) *
        params.pagination.perPage,
      limit: params.pagination.perPage,
    };

    const filter = params.filter;

    switch (resource) {
      case ApiResource.Users: {
        const {
          users,
          count
        } = await usersGetList(request, filter);
        data = users;
        total = count;
        break;
      }
      case ApiResource.Companies: {
        const {
          companies,
          count
        } = await companiesGetList(request, filter);
        data = companies;
        total = count;
        break;
      }
      case ApiResource.AccessTimes: {
        const {
          accessTimes,
          count
        } = await accessTimesGetList(request, filter);
        data = accessTimes;
        total = count;
        break;
      }
      case ApiResource.MapanyServers: {
        const {
          mapanyServers,
          count
        } = await mapanyServersGetList(request, filter);
        data = mapanyServers;
        total = count;
        break;
      }
    }

    const result = {
      data: data as RecordType[],
      total
    };
    return result;
  }
  async getOne<RecordType extends Record>(
    resource: string,
    params: GetOneParams
  ){
    // console.log('getOne', params);
    let data = {} as Record;
    const id = +params.id;

    switch (resource) {
      case ApiResource.Users: {
        const user = await usersGetOne(id);
        const companyAdmin = await isCompanyAdmin(id);
        const accessList = await getAccessListUser(id);
        const functionalities = await getFunctionalityListUser(id);
        
        data = {
          ...user,
          companyAdmin,
          accesses: {
            load: {
              getAccessListUser: accessList
            },
            submit: {
            }
          },
          functionality: {
            load: {
              getFunctionalityListUser: functionalities
            },
            submit: {
            }
          }
        };
        break;
      }
      case ApiResource.Companies: {
        const company = await companiesGetOne(id);
        const accessList = await getAccessListCompany(id);
        const functionalities = await getFunctionalityListCompany(id);
        
        data = {
          ...company,
          accesses: {
            load: {
              getAccessListCompany: accessList
            },
            submit: {
            }
          },
          functionality: {
            load: {
              getFunctionalityListCompany: functionalities
            },
            submit: {
            }
          }
        };
        break;
      }
      case ApiResource.MapanyServers: {
        const mapanyServer = await mapanyServersGetOne(id);
        data = mapanyServer;
        break;
      }
    }

    return {
      data: data as RecordType
    };
  }
  async getMany<RecordType extends Record>(
    resource: string,
    params: GetManyParams
  ){
    // console.log('getMany', params);
    let records = [] as Record[];
    const ids = params.ids.map(id => +id);

    switch (resource) {
      case ApiResource.Users: {
        const users = await usersGetMany(ids);
        records = users;
        break;
      }
      case ApiResource.Companies: {
        const companies = await companiesGetMany(ids);
        records = companies;
        break;
      }
    }

    const result = {
      data: records as RecordType[]
    };
    return result;
  }
  getManyReference(resource: string, params: GetManyReferenceParams){
    console.log('getManyReference', params);
    return null as any;
  }
  async update(resource: string, params: UpdateParams){
    // console.log('update', params);
    let data = params.data;

    switch (resource) {
      case ApiResource.Users: {
        const {
          accesses: previousAccesses,
          functionality: previousFunctionality,
          companyAdmin: previousCompanyAdmin,
          ...previousUser
        } = params.previousData;
        const {
          accesses,
          functionality,
          companyAdmin,
          ...user
        } = data;

        if (
          JSON.stringify(previousUser) !==
          JSON.stringify(user)
        ) {
          await usersUpdate(data);
        }

        if (
          previousCompanyAdmin !==
          companyAdmin
        ) {
          await setCompanyAdmin(+params.id, companyAdmin);
        }

        if (accesses.submit.createAccessUser) {
          await createAccessUser(
            accesses.submit.createAccessUser
          );
        }
        if (accesses.submit.removeAccessUser) {
          await removeAccessUser(
            accesses.submit.removeAccessUser
          );
        }
        if (functionality.submit.createFunctionalityUser) {
          await createFunctionalityUser(
            functionality.submit.createFunctionalityUser
          );
        }
        if (functionality.submit.removeFunctionalityUser) {
          await removeFunctionalityUser(
            functionality.submit.removeFunctionalityUser
          );
        }

        break;
      }
      case ApiResource.Companies: {
        const {
          accesses: previousAccesses,
          functionality: previousFunctionality,
          ...previousCompany
        } = params.previousData;
        const {
          accesses,
          functionality,
          ...company
        } = data;

        if (
          JSON.stringify(previousCompany) !==
          JSON.stringify(company)
        ) {
          await companiesUpdate(data);
        }

        if (accesses.submit.createAccessCompany) {
          await createAccessCompany(
            accesses.submit.createAccessCompany
          );
        }
        if (accesses.submit.removeAccessCompany) {
          await removeAccessCompany(
            accesses.submit.removeAccessCompany
          );
        }

        if (functionality.submit.createFunctionalityCompany) {
          await createFunctionalityCompany(
            functionality.submit.createFunctionalityCompany
          );
        }
        if (functionality.submit.removeFunctionalityCompany) {
          await removeFunctionalityCompany(
            functionality.submit.removeFunctionalityCompany
          );
        }
        break;
      }
      case ApiResource.MapanyServers: {
        await mapanyServersUpdate(data);
        break;
      }
    }

    return {
      data
    };
  }
  updateMany(resource: string, params: UpdateManyParams){
    console.log('updateMany', params);
    return null as any;
  }
  async create(resource: string, params: CreateParams){
    // console.log('create', params);
    let data = params.data;
    let id = 0;

    switch (resource) {
      case ApiResource.Users: {
        const {
          companyAdmin,
          ...user
        } = data;
        id = await usersCreate(user);
        if (companyAdmin) {
          await setCompanyAdmin(id, companyAdmin);
        }
        break;
      }
      case ApiResource.Companies: {
        id = await companiesCreate(data);
        break;
      }
      case ApiResource.MapanyServers: {
        id = await mapanyServersCreate(data);
        break;
      }
    }

    return {
      data: {
        id,
        ...data
      }
    };
  }
  async delete(
    resource: string,
    params: DeleteParams
  ){
    // console.log('delete', params);
    const id = +params.id;

    switch (resource) {
      case ApiResource.Users: {
        await usersDelete(id);
        break;
      }
      case ApiResource.Companies: {
        await companiesDelete(id);
        break;
      }
      case ApiResource.MapanyServers: {
        await mapanyServersDelete(id);
        break;
      }
    }

    const result = {
      data: params.previousData as any
    };
    return result;
  }
  async deleteMany(resource: string, params: DeleteManyParams){
    // console.log('deleteMany', params);
    const ids = params.ids.map(id => +id);

    switch (resource) {
      case ApiResource.Users: {
        for (let id of ids) {
          await usersDelete(id);
        }
        break;
      }
      case ApiResource.Companies: {
        for (let id of ids) {
          await companiesDelete(id);
        }
        break;
      }
      case ApiResource.MapanyServers: {
        for (let id of ids) {
          await mapanyServersDelete(id);
        }
        break;
      }
    }

    return {
      data: ids
    };
  }
};
