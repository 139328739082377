import React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  required,
  useTranslate
} from 'react-admin';
import OnlySaveToolbar from '../../components/OnlySaveToolbar';
import CustomFormTab from '../../components/CustomFormTab';
import AccessesInput, {
  AccessesType
} from '../../components/accesses-input';
import FunctionalityInput, {
  FunctionalityType
} from '../../components/functionality-input';
import Mail from './Mail';

const CompaniesTitle = ({ record }: any) => {
  const translate = useTranslate();
  const title = ''.concat(
    translate('resources.companies.name', 1),
    ' ',
    record ? 
      record.name :
    ''
  );
  return <span>{title}</span>;
};

const CompaniesEdit: React.FC = (props: any) => {
  const translate = useTranslate();
  return (
    <Edit
      title={<CompaniesTitle/>}
      undoable={false}
      {...props}
    >
      <TabbedForm
        redirect={false}
        toolbar={<OnlySaveToolbar/>}
      >
        <FormTab label={translate('pages.companies.tabs.summary')}>
          <TextInput source='id' disabled/>
          <TextInput
            source='name'
            validate={[
              required()
            ]}
          />
          <TextInput
            source='fullname'
            fullWidth
            multiline
            validate={[
              required()
            ]}
          />
          <TextInput source='phone'/>
          <TextInput source='payment'/>
          <TextInput source='email'/>
          <TextInput source='supportEmail'/>
          <BooleanInput source='isAcceptingUsers'/>
          <BooleanInput source='hackAviaBriefingServer'/>
          <TextInput
            source='misc'
            fullWidth
            multiline
          />
        </FormTab>
        <CustomFormTab
          label={translate('pages.companies.tabs.accesses')}
        >
          <AccessesInput
            source={'accesses'}
            type={AccessesType.Company}
          />
        </CustomFormTab>
        <CustomFormTab
          label={translate('pages.companies.tabs.functionality')}
        >
          <FunctionalityInput
            source={'functionality'}
            type={FunctionalityType.Company}
          />
        </CustomFormTab>
        <CustomFormTab
          label={translate('pages.companies.tabs.mail')}
        >
          <Mail
            id={+props.id}
          />
        </CustomFormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CompaniesEdit;
