import React from 'react';
import {
  InputProps,
  useTranslate
} from 'react-admin';

import {
  Paper,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography
 } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  makeStyles
} from '@material-ui/core/styles';

import {
  useFunctionality
} from './hooks';

const useStyles = makeStyles({
  headCell: {
    fontWeight: 'bold'
  },
  container: {
    maxHeight: '100%',
  },
});

export enum FunctionalityType {
  User,
  Company
}

interface FunctionalityProps {
  type: FunctionalityType;
};

type FunctionalityInputProps = InputProps<FunctionalityProps>;

const FunctionalityInput: React.FC<FunctionalityInputProps> = (props) => {
  const translate = useTranslate();

  const {
    functionalityRows,
    onChange
  } = useFunctionality(props.source, props.type);
  const classes = useStyles();

  return (
    <Paper
      style={{
        height: '75vh',
        width: '100%'
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        style={{
          height: '100%'
        }}
      >
        <Box
          style={{
            height: 'calc(100%)'
          }}
        >
          <TableContainer
            component={Paper}
            className={classes.container}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.headCell}
                  >
                    {translate('components.functionality.columns.name')}
                  </TableCell>
                  {props.type === FunctionalityType.Company ?
                    <TableCell
                      align='right'
                      className={classes.headCell}
                    >
                      {translate('components.functionality.columns.usersLimit')}
                    </TableCell> : null
                  }
                  <TableCell
                    align='right'
                    className={classes.headCell}
                  >
                    {translate('components.functionality.columns.devicesLimit')}
                  </TableCell>
                  <TableCell
                    align='right'
                    className={classes.headCell}
                  >
                    {translate('components.functionality.columns.expiresAt')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {functionalityRows.map(functionalityRow => (
                  <TableRow key={functionalityRow.name}>
                    <TableCell component='th' scope='row'>
                      <Typography
                        className={classes.headCell}
                      >
                        {functionalityRow.title}
                      </Typography>
                      <Typography>
                        {functionalityRow.description}
                      </Typography>
                    </TableCell>
                    {props.type === FunctionalityType.Company ?
                      <TableCell align='right'>
                        <TextField
                          id={'usersLimit'}
                          variant={'filled'}
                          value={functionalityRow.usersLimit}
                          onChange={(e) => {
                            onChange({
                              ...functionalityRow,
                              usersLimit: e.target.value
                            });
                          }}
                        />
                      </TableCell> : null
                    }
                    <TableCell align='right'>
                      <TextField
                        id={'devicesLimit'}
                        variant={'filled'}
                        value={functionalityRow.devicesLimit}
                        onChange={(e) => {
                          onChange({
                            ...functionalityRow,
                            devicesLimit: e.target.value
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                      >
                        <KeyboardDatePicker
                          disableToolbar
                          variant='inline'
                          format='dd.MM.yyyy'
                          margin='normal'
                          id='date-picker-inline'
                          value={functionalityRow.expiresAt}
                          autoOk
                          onChange={(date) => {
                            onChange({
                              ...functionalityRow,
                              expiresAt: date
                            });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Paper>
  );
};

export default FunctionalityInput;
