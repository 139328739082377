import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required
} from 'react-admin';

const CompaniesCreate: React.FC = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source='name'
          validate={[
            required()
          ]}
        />
        <TextInput
          source='fullname'
          fullWidth
          multiline
          validate={[
            required()
          ]}
        />
        <TextInput source='phone'/>
        <TextInput source='payment'/>
        <TextInput source='email'/>
        <TextInput source='supportEmail'/>
        <BooleanInput
          source='isAcceptingUsers'
          initialValue={false}
        />
        <BooleanInput
          source='hackAviaBriefingServer'
          initialValue={false}
        />
        <TextInput
          source='misc'
          fullWidth
          multiline
        />
      </SimpleForm>
    </Create>
  );
};

export default CompaniesCreate;
