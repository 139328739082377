import React from 'react';
import { 
  Login
} from 'react-admin';

const RandomImageLoginPage: React.FC<any> = (props) => {
  return (
    <Login
      backgroundImage={
        'https://source.unsplash.com/random/1600x900'
      }
    />
  );
};

export default RandomImageLoginPage;