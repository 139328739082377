import { I18nProvider } from 'react-admin';
import russianMessages from 'ra-language-russian';
import Polyglot from 'node-polyglot';
import { ApiResource } from './api';
import PageUsersI18n from '../pages/users/i18n.json';
import PageCompaniesI18n from '../pages/companies/i18n.json';
import PageAccessTimesI18n from '../pages/access-times/i18n.json';
import PageMapanyServersI18n from '../pages/mapany-servers/i18n.json';
import ComponentsAccessesI18n from '../components/accesses-input/i18n.json';
import ComponentsFunctionalityI18n from '../components/functionality-input/i18n.json';

export default class RussianProvider implements I18nProvider {
  private polyglot: Polyglot;
  constructor() {
    this.polyglot = new Polyglot({
      locale: 'ru',
      phrases: {
        ...russianMessages,
        resources: {
          [ApiResource.Users]: PageUsersI18n.resource,
          [ApiResource.Companies]: PageCompaniesI18n.resource,
          [ApiResource.AccessTimes]: PageAccessTimesI18n.resource,
          [ApiResource.MapanyServers]: PageMapanyServersI18n.resource
        },
        pages: {
          users: PageUsersI18n.page,
          companies: PageCompaniesI18n.page
        },
        components: {
          accesses: ComponentsAccessesI18n,
          functionality: ComponentsFunctionalityI18n
        }
      }
  });
  }
  translate(key: string, options: any) {
    return this.polyglot.t(key, options);
  }
  changeLocale(locale: string, options: any) {
    return Promise.resolve();
  }
  getLocale() {
    return 'ru';
  }
};
