import {
  login,
  checkAuth,
  logout
} from './api';

export interface LoginParams {
  username: string;
  password: string;
}

export default class AuthProvider {
  async login(params: LoginParams) {
    await login(params);
  }
  async logout(params: any) {
    logout();
  }
  checkAuth(params: any) {
    const isLoggedIn = checkAuth();
    if (!isLoggedIn) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  }
  checkError(params: any) {
    if (`${params}` === 'Error: Доступ запрещён. ') {
      logout();
      return Promise.reject();
    }
    return Promise.resolve();
  }
  async getPermissions(params: any) {
  }
};
