import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  PasswordInput,
  ReferenceInput,
  AutocompleteInput,
  required
} from 'react-admin';
import { ApiResource } from '../../lib/api';

const UsersCreate: React.FC = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput
          source='username'
          validate={[
            required()
          ]}
        />
        <PasswordInput
          source='password'
          validate={[
            required()
          ]}
        />
        <TextInput
          source='email'
          validate={[
            required()
          ]}
        />
        <BooleanInput
          source='tester'
          initialValue={false}
        />
        <BooleanInput
          source='companyAdmin'
          initialValue={false}
        />
        <BooleanInput
          source='superAdmin'
          initialValue={false}
        />
        <TextInput
          source='position'
          fullWidth
          multiline
        />
        <ReferenceInput
          source='companyId'
          reference={ApiResource.Companies}
          filterToQuery={(searchText: string) => ({
            name: [searchText]
          })}
          allowEmpty
          perPage={150}
          sort={{
            field: 'name',
            order: 'ASC'
          }}
          defaultValue={0}
        >
          <AutocompleteInput
            optionText='name'
            allowEmpty
            emptyValue={0}
          />
        </ReferenceInput>
        <BooleanInput
          source='dsp'
          initialValue={false}
        />
        <BooleanInput
          source='multi'
          initialValue={false}
        />
        <TextInput source='firstname'/>
        <TextInput source='middlename'/>
        <TextInput source='lastname'/>
        <TextInput source='phone'/>
        <TextInput
          source='description'
          fullWidth
          multiline
        />
      </SimpleForm>
    </Create>
  );
};

export default UsersCreate;