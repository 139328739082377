import Queue from './queue';

const devBaiAdminHostName = 'admin-staging.baionline.ru';
const devBaiApiHost = 'https://staging.baionline.ru';
const prodBaiApiHost = 'https://baionline.ru';

export enum ApiResource {
  Auth = 'auth',
  Users = 'users',
  Companies = 'companies',
  AccessTimes = 'accesstimes',
  MapanyServers = 'mapanyservers',
  Access = 'access'
}

export interface ApiData {
  data: any;
  errors?: any[];
}

export interface SignInRequest {
  username: string;
  password: string;
}

export interface SignInResponse {
  login: string;
  jwt: string;
  rt: string;
  rtBai: string;
  jwtBai: string;
}

export interface GetListRequestMain {
  sortName: string;
  sortAsc: boolean;
  limit: number;
  offset: number;
}

export interface FilterUser {
  id?: string;
  username?: string;
  email?: string;
  tester?: string;
  companyAdmin?: string;
  superAdmin?: string;
  position?: string;
  companyId?: string;
  dsp?: string;
  multi?: string;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  phone?: string;
  description?: string;
};

export interface FilterCompany {
  id?: string;
  name?: string;
  fullname?: string;
  phone?: string;
  payment?: string;
  email?: string;
  supportEmail?: string;
  isAcceptingUsers?: string;
  hackAviaBriefingServer?: string;
  misc?: string;
};

export interface FilterStatistic {
  login?: string;
  userId?: string;
  companyId?: string;
  time?: string;
};

export interface FilterMapAni {
  id?: string;
  name?: string;
  companyId?: string;
  host?: string;
  key?: string;
  isEnabled?: string;
  hostName?: string;
};

export interface User {
  id: number;
  username: string;
  email: string;
  tester: boolean;
  companyAdmin: boolean;
  superAdmin: boolean;
  position: string;
  companyId: number;
  dsp: boolean;
  multi: boolean;
  firstname: string;
  middlename: string;
  lastname: string;
  phone: string;
  description: string;
  deleted: boolean;
  enabled: boolean;
};

export interface UserInput {
  id: number;
  username: string;
  password: string;
  email: string;
  tester: boolean;
  superAdmin: boolean;
  position: string;
  companyId: number;
  dsp: boolean;
  multi: boolean;
  firstname: string;
  middlename: string;
  lastname: string;
  phone: string;
  description: string;
};

export interface Company {
  id: number;
  name: string;
  fullname: string;
  phone: string;
  payment: string;
  email: string;
  supportEmail: string;
  isAcceptingUsers: boolean;
  hackAviaBriefingServer: boolean;
  misc: string;
}

export interface CompanyInput {
  id: number;
  name: string;
  fullname: string;
  phone: string;
  payment: string;
  email: string;
  supportEmail: string;
  isAcceptingUsers: boolean;
  hackAviaBriefingServer: boolean;
  misc: string;
}

export interface Statistic {
  id: number;
  userId: number;
  companyId: number;
  time: string;
};

export interface Server {
  id: number;
  name: string;
  companyId: number;
  host: string;
  key: string;
  isEnabled: boolean;
  hostName: string;
};

export interface ServerInput {
  id: number;
  name: string;
  companyId: number;
  host: string;
  key: string;
  isEnabled: boolean;
  hostName: string;
};

export interface CustomData {
  uuid: string;
  name: string;
  expiresAt: string;
  usersLimit: number;
}

export interface MapBox {
  data: CustomData;
  type: string;
}

export interface DistrictSchema {
  data: CustomData;
  printLimit: number;
};

export interface AHSchema {
  data: DistrictSchema;
  type: string;
  flightRules: string;
  locationIndicatorIcao: string;
};

export interface AccessList {
  navData: CustomData[];
  procedures: CustomData[];
  cartographyMap: CustomData[];
  mapBox: MapBox[];
  districtSchema: DistrictSchema[];
  ahSchema: AHSchema[];
};

export interface InputAccessListRemove {
  id: number;
  navData?: string[];
  procedures?: string[];
  cartographyMap?: string[];
  mapBox?: string[];
  districtSchema?: string[];
  ahSchema?: string[];
};

export interface InputAccessListCreate {
  accessList: InputAccessListRemove;
  date?: string;
  printLimits?: number;
  userLimits?: number;
};

export interface InputAccessListRemove {
  id: number;
  navData?: string[];
  procedures?: string[];
  cartographyMap?: string[];
  mapBox?: string[];
  districtSchema?: string[];
  ahSchema?: string[];
};

export interface Functionality {
  name: string;
  expiresAt: string;
  usersLimit: number;
  devicesLimit: number;
};

export interface FunctionalityList {
  id: number;
  list: Functionality[];
}

export interface InputFunctionality {
  name: string;
  expiresAt: string;
  usersLimit: number;
  devicesLimit: number;
};

export interface InputFunctionalityList {
  id: number;
  list: InputFunctionality[];
}

export interface InputFunctionalityRemove {
  id: number;
  functionalityList: string[];
}

export interface AdminTokenRequest {
  type: string;
  token: string;
}

export interface AuthRefreshResponse {
  token: string;
  refreshToken: string;
  tokenExpiry: string;
};

export interface AdminMailRequest {
  id: number;
  content: string;
  test?: string;
}

export interface AdminMailAviatorAccessRequest {
  accesses: number[];
}

const text = (value?: string) =>
  value ? `"${`${value}`
    .replace(/\\/g, '\\\\')
    .replace(/[\r\n]+/g, '\\n')
    .replace(/"/g, '\\"')}"` : '""';

const obj = (object: Object) => '{'.concat(
    Object.entries(object).map(
      ([ key, value]) => `${key}: ${text(value)}`
    ).join(','),
  '}'
);

const number = (value?: number) => value || 0;

const callGraphQueue = new Queue<any>();

const callGraph = async (
  query: string,
  ...params: [AbortSignal?] | [File, AbortSignal?]
) => {
  const run = async () => {
    let file = undefined as File | undefined;
    let signal = undefined as AbortSignal | undefined;
    const [ first, second ] = params;
    if (first) {
      if (first instanceof File) {
        file = first;
      }
      if (first instanceof AbortSignal) {
        signal = first;
      }
    }
    if (second) {
      signal = second;
    }
    const headers = {
    } as any;
    if (
      (localStorage.getItem('login') || '').length > 0 &&
      (localStorage.getItem('jwt') || '').length > 0 &&
      (localStorage.getItem('rt') || '').length > 0
    ) {
      headers['Auth-Login'] = (localStorage.getItem('login') || '');
      headers['Auth-JWT'] = (localStorage.getItem('jwt') || '');
      headers['Auth-RT'] = (localStorage.getItem('rt') || '');
    }
    let body: any;
    if (!file) {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify({query})
    } else {
      const formData = new FormData();
      formData.append('operations', JSON.stringify({
        query,
        variables: {
          file: null
        }
      }));
      formData.append('map', JSON.stringify({
        '0': [
          'variables.file'
        ]
      }));
      formData.append('0', file);
      body = formData;
    }

    const res = await fetch(`/api`, {
      method: 'POST',
      headers,
      body
    });

    const login = res.headers.get('Auth-Login');
    const jwt = res.headers.get('Auth-JWT');
    const rt = res.headers.get('Auth-Rt');

    if (login && jwt && rt) {
      localStorage.setItem('login', login);
      localStorage.setItem('jwt', jwt);
      localStorage.setItem('rt', rt);
    }

    if (signal && signal.aborted) {
      throw new Error('abort');
    }
    const data = await res.json() as ApiData;
    if (data.errors) {
      throw new Error(
        data.errors[0].message
      );
    }
    return data.data;
  };

  return callGraphQueue.call(run);
};

const isProd = () => {
  if (typeof window !== 'undefined') {
    const location = window.location;
    const isSecure = location.protocol === 'https:';
    const isDev = location.hostname === devBaiAdminHostName;
    return isSecure && !isDev;
  } else {
    return false;
  }
};

const getBaiApiHost = () => {
  const baiApiHost = process.env.REACT_APP_BAI_API_HOST ||
    (isProd() ? prodBaiApiHost : devBaiApiHost);
  return baiApiHost;
};

let jwtBaiPromise = null as Promise<string> | null;

const getJwtBai = (params?: AbortSignal) => {
  if (
    jwtBaiPromise
  ) {
    return jwtBaiPromise;
  }
  const baiTokenExpiry = localStorage.getItem('baiTokenExpiry');
  const jwtBai = localStorage.getItem('jwtBai');
  if (baiTokenExpiry && jwtBai) {
    const baiTokenExpiryTime = Date.parse(baiTokenExpiry + ' GMT');
    if (new Date().getTime() < baiTokenExpiryTime) {
      return Promise.resolve(jwtBai);
    }
  }

  const signal = params;
  const baiApiHost = getBaiApiHost();

  const rt = localStorage.getItem('rtBai');
  if (!rt) {
    return Promise.resolve('');
  }
  jwtBaiPromise = new Promise<string>((resolve, reject) => {
    fetch(
      `${baiApiHost}/api/auth/refresh`,
      {
        method: 'POST',
        signal,
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          refresh_token: rt
        })
      }
    ).then(res => res.json(
    )).then((authRefreshResponse: AuthRefreshResponse) => {
      const {
        token,
        refreshToken,
        tokenExpiry,
      } = authRefreshResponse;
      localStorage.setItem('jwtBai', token);
      localStorage.setItem('rtBai', refreshToken);
      localStorage.setItem('baiTokenExpiry', tokenExpiry);
      resolve(token);
    }).catch(reject)
      .finally(() => {
        jwtBaiPromise = null;
      });
  });
  return jwtBaiPromise;
};

const callBai = async (
  route: string,
  body: any,
  params?: AbortSignal
) => {
  const signal = params;
  const baiApiHost = getBaiApiHost();

  const jwtBai = await getJwtBai(signal);

  const res = await fetch(`${baiApiHost}/api/${route}`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwtBai}`
    }),
    body: JSON.stringify(body),
    signal
  });

  const data = await res.json() as any;
  return data;
};

const signin = async (
  signInRequest: SignInRequest,
  signal?: AbortSignal
) => {
  const query = `query {
    signIn(
      user: {
        username: ${text(signInRequest.username)},
        password: ${text(signInRequest.password)}
      }
    ){
      login,
      jwt,
      rt,
      rtBai,
      jwtBai
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );

  const signInResponse = data.signIn as SignInResponse;
  return signInResponse;
};

const login = async (
  signInRequest: SignInRequest,
  signal?: AbortSignal
) => {
  const signInResponse = await signin(
    signInRequest,
    signal
  );
  localStorage.setItem('jwt', signInResponse.jwt);
  localStorage.setItem('jwtBai', signInResponse.jwtBai);
  localStorage.setItem('login', signInResponse.login);
  localStorage.setItem('rt', signInResponse.rt);
  localStorage.setItem('rtBai', signInResponse.rtBai);
};

const logout = async (
) => {
  localStorage.removeItem('jwt');
  localStorage.removeItem('jwtBai');
  localStorage.removeItem('login');
  localStorage.removeItem('rt');
  localStorage.removeItem('rtBai');
  localStorage.removeItem('baiTokenExpiry');
};

const checkAuth = () => {
  return !!localStorage.getItem('jwt');
};

const usersGetList = async (
  request: GetListRequestMain,
  filter: FilterUser,
  signal?: AbortSignal
) => {
  const query = `query {
    getListUser(
      request: {
        requestList: {
          sortName: ${text(request.sortName)},
          sortAsc: ${request.sortAsc},
          limit: ${request.limit},
          offset: ${request.offset},
        },
        filter: ${obj(filter)}
      }
    ){
      users{
        id,
        username,
        email,
        tester,
        companyAdmin,
        superAdmin,
        position,
        companyId,
        dsp,
        multi,
        firstname,
        middlename,
        lastname,
        phone,
        description,
        deleted,
        enabled
      },
      count
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const users = data.getListUser.users as User[]
    || [];
  const count = data.getListUser.count as number;
  return {
    users,
    count
  };
};

const usersGetMany = async (
  ids: number[],
  signal?: AbortSignal
) => {
  const query = `query {
    getManyUser(
      request: {
        ids: [${ids}]
      }
    ){
      users{
        id,
        username,
        email,
        tester,
        companyAdmin,
        superAdmin,
        position,
        companyId,
        dsp,
        multi,
        firstname,
        middlename,
        lastname,
        phone,
        description,
        deleted,
        enabled
      },
      count
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const users = data.getManyUser.users as User[]
    || [];
  return users;
};

const usersGetOne = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `query {
    getOneUser(
      request: {
        id: ${id}
      }
    ){
      id,
      username,
      email,
      tester,
      superAdmin,
      position,
      companyId,
      dsp,
      multi,
      firstname,
      middlename,
      lastname,
      phone,
      description,
      deleted,
      enabled
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const user = data.getOneUser as User;
  return user;
};

const usersUpdate = async (
  user: UserInput,
  signal?: AbortSignal
) => {
  const query = `mutation {
    updateUser(
      request: {
        id: ${user.id},
        username: ${text(user.username)},
        password: ${text(user.password)},
        email: ${text(user.email)},
        tester: ${user.tester},
        superAdmin: ${user.superAdmin},
        position: ${text(user.position)},
        companyId: ${number(user.companyId)},
        dsp: ${user.dsp},
        multi: ${user.multi},
        firstname: ${text(user.firstname)},
        middlename: ${text(user.middlename)},
        lastname: ${text(user.lastname)},
        phone: ${text(user.phone)},
        description: ${text(user.description)},
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.updateUser.success as boolean;
  return success;
};

const usersCreate = async (
  user: UserInput,
  signal?: AbortSignal
) => {
  const query = `mutation {
    createUser(
      request: {
        username: ${text(user.username)},
        password: ${text(user.password)},
        email: ${text(user.email)},
        tester: ${user.tester},
        superAdmin: ${user.superAdmin},
        position: ${text(user.position)},
        companyId: ${user.companyId},
        dsp: ${user.dsp},
        multi: ${user.multi},
        firstname: ${text(user.firstname)},
        middlename: ${text(user.middlename)},
        lastname: ${text(user.lastname)},
        phone: ${text(user.phone)},
        description: ${text(user.description)}
      }
    ){
      success,
      id
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.createUser.success as boolean;
  const id = data.createUser.id as number;

  return success ?
    id : 0;
};

const usersDelete = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `mutation {
    deleteUser(
      request: {
        id: ${id}
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.success as boolean;
  return success;
};

const companiesGetList = async (
  request: GetListRequestMain,
  filter: FilterCompany,
  signal?: AbortSignal
) => {
  const query = `query {
    getListCompany(
      request: {
        requestList: {
          sortName: ${text(request.sortName)},
          sortAsc: ${request.sortAsc},
          limit: ${request.limit},
          offset: ${request.offset},
        },
        filter: ${obj(filter)}
      }
    ){
      companies{
        id,
        name,
        fullname,
        phone,
        payment,
        email,
        supportEmail,
        isAcceptingUsers,
        hackAviaBriefingServer,
        misc
      },
      count
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const companies = data.getListCompany.companies as Company[]
    || [];
  const count = data.getListCompany.count as number;
  return {
    companies,
    count
  };
};

const companiesGetMany = async (
  ids: number[],
  signal?: AbortSignal
) => {
  const query = `query {
    getManyCompany(
      request: {
        ids: [${ids}]
      }
    ){
      companies{
        id,
        name,
        fullname,
        phone,
        payment,
        email,
        supportEmail,
        isAcceptingUsers,
        hackAviaBriefingServer,
        misc
      }
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const companies = data.getManyCompany.companies as Company[]
    || [];
  return companies;
};

const companiesGetOne = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `query {
    getOneCompany(
      request: {
        id: ${id}
      }
    ){
      id,
      name,
      fullname,
      phone,
      payment,
      email,
      supportEmail,
      isAcceptingUsers,
      hackAviaBriefingServer,
      misc
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const company = data.getOneCompany as Company;
  return company;
};

const companiesUpdate = async (
  company: CompanyInput,
  signal?: AbortSignal
) => {
  const query = `mutation {
    updateCompany(
      request: {
        id: ${company.id},
        name: ${text(company.name)},
        fullname: ${text(company.fullname)},
        phone: ${text(company.phone)},
        payment: ${text(company.payment)},
        email: ${text(company.email)},
        supportEmail: ${text(company.supportEmail)},
        isAcceptingUsers: ${company.isAcceptingUsers},
        hackAviaBriefingServer: ${company.hackAviaBriefingServer},
        misc: ${text(company.misc)}
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.updateCompany.success as boolean;
  return success;
};

const companiesCreate = async (
  company: CompanyInput,
  signal?: AbortSignal
) => {
  const query = `mutation {
    createCompany(
      request: {
        name: ${text(company.name)},
        fullname: ${text(company.fullname)},
        phone: ${text(company.phone)},
        payment: ${text(company.payment)},
        email: ${text(company.email)},
        supportEmail: ${text(company.supportEmail)},
        isAcceptingUsers: ${company.isAcceptingUsers},
        hackAviaBriefingServer: ${company.hackAviaBriefingServer},
        misc: ${text(company.misc)}
      }
    ){
      success,
      id
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.createCompany.success as boolean;
  const id = data.createCompany.id as number;

  return success ?
    id : 0;
};

const companiesDelete = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `mutation {
    deleteCompany(
      request: {
        id: ${id}
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.success as boolean;
  return success;
};


const accessTimesGetList = async (
  request: GetListRequestMain,
  filter: FilterStatistic,
  signal?: AbortSignal
) => {
  const query = `query {
    getListStatistic(
      request: {
        requestList: {
          sortName: ${text(request.sortName)},
          sortAsc: ${request.sortAsc},
          limit: ${request.limit},
          offset: ${request.offset},
        },
        filter: ${obj(filter)}
      }
    ){
      statistics{
        id,
        userId,
        companyId,
        time
      },
      count
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const accessTimes = data.getListStatistic.statistics as Statistic[]
    || [];
  const count = data.getListStatistic.count as number;
  return {
    accessTimes,
    count
  };
};

const mapanyServersGetList = async (
  request: GetListRequestMain,
  filter: FilterMapAni,
  signal?: AbortSignal
) => {
  const query = `query {
    getListMapAni(
      request: {
        requestList: {
          sortName: ${text(request.sortName)},
          sortAsc: ${request.sortAsc},
          limit: ${request.limit},
          offset: ${request.offset},
        },
        filter: ${obj(filter)}
      }
    ){
      servers{
        id,
        name,
        companyId,
        host,
        key,
        isEnabled,
        hostName
      },
      count
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const mapanyServers = data
    .getListMapAni.servers as Server[] || [];
  const count = data.getListMapAni.count as number;
  return {
    mapanyServers,
    count
  };
};

const mapanyServersGetOne = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `query {
    getOneMapAni(
      request: {
        id: ${id}
      }
    ){
      id,
      name,
      companyId,
      host,
      key,
      isEnabled,
      hostName
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const mapanyServer = data.getOneMapAni as Server;
  return mapanyServer;
};

const mapanyServersUpdate = async (
  mapanyServer: ServerInput,
  signal?: AbortSignal
) => {
  const query = `mutation {
    updateMapAni(
      request: {
        id: ${mapanyServer.id},
        name: ${text(mapanyServer.name)},
        companyId: ${mapanyServer.companyId},
        host: ${text(mapanyServer.host)},
        key: ${text(mapanyServer.key)},
        isEnabled: ${mapanyServer.isEnabled},
        hostName: ${text(mapanyServer.hostName)}
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.updateMapAni.success as boolean;
  return success;
};

const mapanyServersCreate = async (
  mapanyServer: ServerInput,
  signal?: AbortSignal
) => {
  const query = `mutation {
    createMapAni(
      request: {
        name: ${text(mapanyServer.name)},
        companyId: ${mapanyServer.companyId},
        host: ${text(mapanyServer.host)},
        key: ${text(mapanyServer.key)},
        isEnabled: ${mapanyServer.isEnabled},
        hostName: ${text(mapanyServer.hostName)}
      }
    ){
      success,
      id
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.createMapAni.success as boolean;
  const id = data.createMapAni.id as number;

  return success ?
    id : 0;
};

const mapanyServersDelete = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `mutation {
    deleteMapAni(
      request: {
        id: ${id}
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.success as boolean;
  return success;
};

const getAccessListUser = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `query {
    getAccessListUser(
      request: {
        id: ${id}
      }
    ){
      navData {
        uuid,
        name,
        expiresAt,
        usersLimit
      },
      procedures {
        uuid,
        name,
        expiresAt,
        usersLimit
      },
      cartographyMap {
        uuid,
        name,
        expiresAt,
        usersLimit
      },
      mapBox {
        data {
          uuid,
          name,
          expiresAt,
          usersLimit
        },
        type
      },
      districtSchema {
        data {
          uuid,
          name,
          expiresAt,
          usersLimit
        },
        printLimit
      },
      ahSchema {
        data {
          data {
            uuid,
            name,
            expiresAt,
            usersLimit
          },
          printLimit
        },
        type,
        flightRules,
        locationIndicatorIcao
      }
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const accessList = data
    .getAccessListUser as AccessList;
  return accessList;
};

const createAccessUser = async (
  request: InputAccessListCreate,
  signal?: AbortSignal
) => {

  const query = `mutation {
    createAccessUser(
      request: {
        accessList: {
          id: ${request.accessList.id},
          navData: [${
            (request.accessList.navData || [])
              .map(uuid => text(uuid))
              .join()
          }],
          procedures: [${
            (request.accessList.procedures || [])
              .map(uuid => text(uuid))
              .join()
          }],
          cartographyMap: [${
            (request.accessList.cartographyMap || [])
              .map(uuid => text(uuid))
              .join()
          }],
          mapBox: [${
            (request.accessList.mapBox || [])
              .map(uuid => text(uuid))
              .join()
          }],
          districtSchema: [${
            (request.accessList.districtSchema || [])
              .map(uuid => text(uuid))
              .join()
          }],
          ahSchema: [${
            (request.accessList.ahSchema || [])
              .map(uuid => text(uuid))
              .join()
          }],
        },
        date: ${text(request.date)},
        printLimits: ${request.printLimits}
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data
    .createAccessUser.success as boolean;

  return success;
};

const removeAccessUser = async (
  request: InputAccessListRemove,
  signal?: AbortSignal
) => {

  const query = `mutation {
    removeAccessUser(
      request: {
        id: ${request.id},
        navData: [${
          (request.navData || [])
            .map(uuid => text(uuid))
            .join()
        }],
        procedures: [${
          (request.procedures || [])
            .map(uuid => text(uuid))
            .join()
        }],
        cartographyMap: [${
          (request.cartographyMap || [])
            .map(uuid => text(uuid))
            .join()
        }],
        mapBox: [${
          (request.mapBox || [])
            .map(uuid => text(uuid))
            .join()
        }],
        districtSchema: [${
          (request.districtSchema || [])
            .map(uuid => text(uuid))
            .join()
        }],
        ahSchema: [${
          (request.ahSchema || [])
            .map(uuid => text(uuid))
            .join()
        }]
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data
    .removeAccessUser.success as boolean;

  return success;
};

const getFunctionalityListUser = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `query {
    getFunctionalityListUser(
      request: {
        id: ${id}
      }
    ){
      list{
        name,
        expiresAt,
        usersLimit,
        devicesLimit
      }
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const fucntionalityList = data
    .getFunctionalityListUser.list as Functionality[] || [];
  return fucntionalityList;
};

const createFunctionalityUser = async (
  request: InputFunctionalityList,
  signal?: AbortSignal
) => {
  const query = `mutation {
    createFunctionalityUser(
      request: {
        id: ${request.id},
        list: [${
          (request.list || [])
            .map(functionality => `{
              name: ${text(functionality.name)},
              expiresAt: ${text(functionality.expiresAt)},
              devicesLimit: ${functionality.devicesLimit}
            }`)
            .join()
        }]
      }
    ){
      success,
      ids
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data
    .createFunctionalityUser.success as boolean;

  const aviatorAccessesIds = data
    .createFunctionalityUser.ids as number[] | null;
  if (aviatorAccessesIds && aviatorAccessesIds.length) {
    adminMailAviatorAccess(
      {
        accesses: aviatorAccessesIds
      },
      signal
    )
  }
  return success;
};

const removeFunctionalityUser = async (
  request: InputFunctionalityRemove,
  signal?: AbortSignal
) => {
  const query = `mutation {
    removeFunctionalityUser(
      request: {
        id: ${request.id},
        functionalityList: [${
          (request.functionalityList || [])
            .map(name => text(name))
            .join()
        }]
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data
    .removeFunctionalityUser.success as boolean;

  return success;
};

const getAccessListCompany = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `query {
    getAccessListCompany(
      request: {
        id: ${id}
      }
    ){
      navData {
        uuid,
        name,
        expiresAt,
        usersLimit
      },
      procedures {
        uuid,
        name,
        expiresAt,
        usersLimit
      },
      cartographyMap {
        uuid,
        name,
        expiresAt,
        usersLimit
      },
      mapBox {
        data {
          uuid,
          name,
          expiresAt,
          usersLimit
        },
        type
      },
      districtSchema {
        data {
          uuid,
          name,
          expiresAt,
          usersLimit
        },
        printLimit
      },
      ahSchema {
        data {
          data {
            uuid,
            name,
            expiresAt,
            usersLimit
          },
          printLimit
        },
        type,
        flightRules,
        locationIndicatorIcao
      }
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const accessList = data
    .getAccessListCompany as AccessList;
  return accessList;
};

const createAccessCompany = async (
  request: InputAccessListCreate,
  signal?: AbortSignal
) => {

  const query = `mutation {
    createAccessCompany(
      request: {
        accessList: {
          id: ${request.accessList.id},
          navData: [${
            (request.accessList.navData || [])
              .map(uuid => text(uuid))
              .join()
          }],
          procedures: [${
            (request.accessList.procedures || [])
              .map(uuid => text(uuid))
              .join()
          }],
          cartographyMap: [${
            (request.accessList.cartographyMap || [])
              .map(uuid => text(uuid))
              .join()
          }],
          mapBox: [${
            (request.accessList.mapBox || [])
              .map(uuid => text(uuid))
              .join()
          }],
          districtSchema: [${
            (request.accessList.districtSchema || [])
              .map(uuid => text(uuid))
              .join()
          }],
          ahSchema: [${
            (request.accessList.ahSchema || [])
              .map(uuid => text(uuid))
              .join()
          }],
        },
        date: ${text(request.date)},
        printLimits: ${request.printLimits},
        userLimits: ${request.userLimits}
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data
    .createAccessCompany.success as boolean;

  return success;
};

const removeAccessCompany = async (
  request: InputAccessListRemove,
  signal?: AbortSignal
) => {

  const query = `mutation {
    removeAccessCompany(
      request: {
        id: ${request.id},
        navData: [${
          (request.navData || [])
            .map(uuid => text(uuid))
            .join()
        }],
        procedures: [${
          (request.procedures || [])
            .map(uuid => text(uuid))
            .join()
        }],
        cartographyMap: [${
          (request.cartographyMap || [])
            .map(uuid => text(uuid))
            .join()
        }],
        mapBox: [${
          (request.mapBox || [])
            .map(uuid => text(uuid))
            .join()
        }],
        districtSchema: [${
          (request.districtSchema || [])
            .map(uuid => text(uuid))
            .join()
        }],
        ahSchema: [${
          (request.ahSchema || [])
            .map(uuid => text(uuid))
            .join()
        }]
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data
    .removeAccessCompany.success as boolean;

  return success;
};

const getFunctionalityListCompany = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `query {
    getFunctionalityListCompany(
      request: {
        id: ${id}
      }
    ){
      list{
        name,
        expiresAt,
        usersLimit,
        devicesLimit
      }
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const fucntionalityList = data
    .getFunctionalityListCompany.list as Functionality[] || [];
  return fucntionalityList;
};

const createFunctionalityCompany = async (
  request: InputFunctionalityList,
  signal?: AbortSignal
) => {
  const query = `mutation {
    createFunctionalityCompany(
      request: {
        id: ${request.id},
        list: [${
          (request.list || [])
            .map(functionality => `{
              name: ${text(functionality.name)},
              expiresAt: ${text(functionality.expiresAt)},
              usersLimit: ${functionality.usersLimit},
              devicesLimit: ${functionality.devicesLimit}
            }`)
            .join()
        }]
      }
    ){
      success,
      ids
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data
    .createFunctionalityCompany.success as boolean;

  const aviatorAccessesIds = data
    .createFunctionalityCompany.ids as number[] | null;
  if (aviatorAccessesIds && aviatorAccessesIds.length) {
    adminMailAviatorAccess(
      {
        accesses: aviatorAccessesIds
      },
      signal
    )
  }

  return success;
};

const removeFunctionalityCompany = async (
  request: InputFunctionalityRemove,
  signal?: AbortSignal
) => {
  const query = `mutation {
    removeFunctionalityCompany(
      request: {
        id: ${request.id},
        functionalityList: [${
          (request.functionalityList || [])
            .map(name => text(name))
            .join()
        }]
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data
    .removeFunctionalityCompany.success as boolean;

  return success;
};

const adminToken = async (
  request: AdminTokenRequest,
  signal?: AbortSignal
) => {
  const data = await callBai(
    'admin/token',
    {
      type: request.type,
      token: request.token
    },
    signal
  );
  return data;
};

const adminMail = async (
  request: AdminMailRequest,
  signal?: AbortSignal
) => {
  const data = await callBai(
    `admin/mail/company/${request.id}`,
    {
      content: request.content,
      test: request.test
    },
    signal
  );
  return data;
};

const adminMailAviatorAccess = async (
  request: AdminMailAviatorAccessRequest,
  signal?: AbortSignal
) => {
  const data = await callBai(
    `admin/mail/aviator`,
    {
      accesses: request.accesses
    },
    signal
  );
  return data;
};

const isCompanyAdmin = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `query {
    isCompanyAdmin(
      request: {
        id: ${id}
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const isCompanyAdmin = data.isCompanyAdmin.success as boolean;
  return isCompanyAdmin;
};

const setCompanyAdmin = async (
  id: number,
  isCompanyAdmin: boolean,
  signal?: AbortSignal
) => {
  const query = `mutation {
    setCompanyAdmin(
      request: {
        id: ${id},
        success: ${isCompanyAdmin}
      }
    ){
      success
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.success as boolean;
  return success;
};

const restoreUser = async (
  id: number,
  signal?: AbortSignal
) => {
  const query = `mutation {
    recoveryUser(
      request: {
        id: ${id}
      }
    ){
      success,
      id
    }
  }`;

  const data = await callGraph(
    query,
    signal
  );
  const success = data.recoveryUser.success as boolean;
  return success;
};

export {
  login,
  logout,
  checkAuth,
  usersGetList,
  usersGetMany,
  usersGetOne,
  usersUpdate,
  usersCreate,
  usersDelete,
  companiesGetList,
  companiesGetMany,
  companiesGetOne,
  companiesUpdate,
  companiesCreate,
  companiesDelete,
  accessTimesGetList,
  mapanyServersGetList,
  mapanyServersGetOne,
  mapanyServersUpdate,
  mapanyServersCreate,
  mapanyServersDelete,
  getAccessListUser,
  createAccessUser,
  removeAccessUser,
  getFunctionalityListUser,
  createFunctionalityUser,
  removeFunctionalityUser,
  getAccessListCompany,
  createAccessCompany,
  removeAccessCompany,
  getFunctionalityListCompany,
  createFunctionalityCompany,
  removeFunctionalityCompany,
  adminToken,
  adminMail,
  isCompanyAdmin,
  setCompanyAdmin,
  restoreUser
};
