import React from 'react';
import {
  Admin,
  Resource
} from 'react-admin';
import ApiProvider from './lib/api-provider';
import AuthProvider from './lib/auth-provider';
import I18nProvider from './lib/i18n-provider';
import RandomImageLoginPage from './pages/login/RandomImageLoginPage';
import { ApiResource } from './lib/api';
import UsersList from './pages/users/UsersList';
import UsersIcon from '@material-ui/icons/Person';
import UsersEdit from './pages/users/UsersEdit';
import UsersCreate from './pages/users/UsersCreate';
import CompaniesList from './pages/companies/CompaniesList';
import CompaniesIcon from '@material-ui/icons/Business';
import AccessTimesList from './pages/access-times/AccessTimesList';
import AccessTimesIcon from '@material-ui/icons/AccessTime';
import CompaniesEdit from './pages/companies/CompaniesEdit';
import CompaniesCreate from './pages/companies/CompaniesCreate';
import MapanyServersList from './pages/mapany-servers/MapanyServersList';
import MapanyServersIcon from '@material-ui/icons/FilterHdr';
import MapanyServersEdit from './pages/mapany-servers/MapanyServersEdit';
import MapanyServersCreate from './pages/mapany-servers/MapanyServersCreate';

const dataProvider = new ApiProvider();
const authProvider = new AuthProvider();
const i18nProvider = new I18nProvider();

const App: React.FC = () => {

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={RandomImageLoginPage}
    >
      <Resource
        name={ApiResource.Users}
        list={UsersList}
        edit={UsersEdit}
        create={UsersCreate}
        icon={UsersIcon}
        options={{ label: 'Users' }}
      />
      <Resource
        name={ApiResource.Companies}
        list={CompaniesList}
        edit={CompaniesEdit}
        create={CompaniesCreate}
        icon={CompaniesIcon}
        options={{ label: 'Companies' }}
      />
      <Resource
        name={ApiResource.AccessTimes}
        list={AccessTimesList}
        icon={AccessTimesIcon}
        options={{ label: 'Access Times' }}
      />
      <Resource
        name={ApiResource.MapanyServers}
        list={MapanyServersList}
        edit={MapanyServersEdit}
        create={MapanyServersCreate}
        icon={MapanyServersIcon}
        options={{ label: 'Mapany Servers' }}
      />
    </Admin>
  );
}

export default App;
