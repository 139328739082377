import React from 'react';
import {
  useTranslate,
  InputProps
} from 'react-admin';

import {
  Paper,
  FormControlLabel,
  Switch,
  Box,
  TextField
} from '@material-ui/core';
import VirtualizedTable, { ColumnData } from '../VirtualizedTable';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  useAccessRows,
  useAccessForm
} from './hooks';

export enum AccessesType {
  User,
  Company
}

interface AccessListProps {
  type: AccessesType;
};

type AccessInputProps = InputProps<AccessListProps>;

const AccessesInput: React.FC<AccessInputProps> = (props) => {
  const translate = useTranslate();

  const {
    accessRows,
    select,
    allSelected,
    selectAll,
    separatorRowIndices,
    isAd,
    switchAd,
    isLs,
    switchLs,
    isIfr,
    switchIfr,
    isVfr,
    switchVfr,
    isOpen,
    switchOpen
  } = useAccessRows(props.source, props.type);

  const {
    printLimit,
    onChangePrintLimit,
    userLimit,
    onChangeUserLimit,
    expiresAt,
    onChangeExpiresAt,
  } = useAccessForm(props.source, props.type, accessRows);
  
  return (
    <Paper
      style={{
        height: '75vh',
        width: '100%'
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        style={{
          height: '100%'
        }}
      >
        <Box
          display='flex'
          flexWrap='wrap'
          alignItems='center'
          justifyContent='space-between'
        >
          <FormControlLabel
            control={
              <Switch
                checked={isAd}
                onChange={switchAd}
                name='checkedAd'
              />}
            label={translate('components.accesses.toolbar.ad')}
          />
          <FormControlLabel
            control={
              <Switch
                checked={isLs}
                onChange={switchLs}
                name='checkedLs'
              />}
            label={translate('components.accesses.toolbar.pp')}
          />
          <FormControlLabel
            control={
              <Switch
                checked={isIfr}
                onChange={switchIfr}
                name='checkedIfr'
              />}
            label={translate('components.accesses.toolbar.ifr')}
          />
          <FormControlLabel
            control={
              <Switch
                checked={isVfr}
                onChange={switchVfr}
                name='checkedVfr'
              />}
            label={translate('components.accesses.toolbar.vfr')}
          />
          <FormControlLabel
            control={
              <Switch
                checked={isOpen}
                onChange={switchOpen}
                name='checkedOpen'
              />}
            label={translate('components.accesses.toolbar.open')}
          />
          {props.type !== AccessesType.User ?
            <TextField
              id={'userLimit'}
              label={translate('components.accesses.toolbar.userLimit')}
              variant={'filled'}
              value={userLimit !== null ? userLimit : ''}
              onChange={e => {
                onChangeUserLimit(e.target.value)
              }}
            /> : null
          }
          <TextField
            id={'printLimit'}
            label={translate('components.accesses.toolbar.printLimit')}
            variant={'filled'}
            value={printLimit !== null ? printLimit : ''}
            onChange={e => {
              onChangePrintLimit(e.target.value)
            }}
          />
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
          >
            <KeyboardDatePicker
              disableToolbar
              variant='inline'
              format='dd.MM.yyyy'
              margin='normal'
              id='date-picker-inline'
              label={translate('components.accesses.toolbar.expiresAt')}
              value={expiresAt}
              autoOk
              onChange={onChangeExpiresAt}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box
          flexGrow={1}
        >
          <VirtualizedTable
            rowCount={accessRows.length}
            rowGetter={({ index }) => accessRows[index]}
            onRowClick={row => select(row.index)}
            allSelected={allSelected}
            onSelectAllClick={selectAll}
            separatorRowIndices={separatorRowIndices}
            columns={[
              {
                width: 52,
                dataKey: 'selected',
                label: 'checkbox',
                type: 'checkbox'
              },
              {
                width: 200,
                dataKey: 'typeName',
                label: translate('components.accesses.columns.typeName'),
                type: 'string'
              },
              {
                width: 400,
                dataKey: 'name',
                label: translate('components.accesses.columns.name'),
                type: 'string'
              },
              ...(props.type !== AccessesType.User ?
                [{
                  width: 120,
                  dataKey: 'usersLimit',
                  label: translate('components.accesses.columns.usersLimit'),
                  type: 'number'
                } as ColumnData] : []
              ),
              {
                width: 120,
                dataKey: 'printLimit',
                label: translate('components.accesses.columns.printLimit'),
                type: 'number'
              },
              {
                width: 120,
                dataKey: 'expiresAt',
                label: translate('components.accesses.columns.expiresAt'),
                type: 'string'
              }
            ]}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default AccessesInput;