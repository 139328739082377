import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  Filter,
  TextInput,
  BooleanInput
} from 'react-admin';

const CompaniesFilter: React.FC = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source='id'/>
      <TextInput source='name'/>
      <TextInput source='fullname'/>
      <TextInput source='phone'/>
      <TextInput source='payment'/>
      <TextInput source='email'/>
      <TextInput source='supportEmail'/>
      <BooleanInput source='isAcceptingUsers'/>
      <BooleanInput source='hackAviaBriefingServer'/>
      <TextInput source='misc'/>
    </Filter>
  );
};

const CompaniesList: React.FC = (props: any) => {
  return (
    <List
      filters={<CompaniesFilter/>}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid
        rowClick='edit'
      >
        <TextField source='id'/>
        <TextField source='name'/>
        <TextField source='fullname'/>
        <TextField source='phone'/>
        <TextField source='payment'/>
        <EmailField source='email'/>
        <EmailField source='supportEmail'/>
        <BooleanField source='isAcceptingUsers'/>
        <BooleanField source='hackAviaBriefingServer'/>
        <TextField source='misc'/>
      </Datagrid>
    </List>
  );
};

export default CompaniesList;